<template>
    <div>Hello About</div>
    <input type="text" v-model="name">
</template>

<script>
import { onDeactivated } from '@vue/runtime-core';
export default {
    data() {
        return {
            name: '',
        }
    },
    setup() {
        onDeactivated(() => {
            console.log('About DEactivated!!!');
        })
    },
    mounted() {
        console.log('About has been mounted');
    },
    activated() {
        console.log('About Activated!!!');
    }
}
</script>