<template>
    <div>
        <button v-for="tab in tabs" :key="tab" @click="component = tab">
            {{tab}}
        </button>
        <keep-alive>
            <component :is="component" />
         </keep-alive>
    </div>
</template>

<script>
import About from './About.vue'
import Contact from './Contact.vue'

export default {
    components: {
        About,
        Contact
    },
    data() {
        return {
            tabs: ["About", "Contact"],
            component: "About"
        }
    },
    mounted() {
        console.log('About has been mounted');
    }
}
</script>